import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addArrayCreos, resetCreos } from '../../store/creosReducer'
import { getFreeCreos } from '../../http/creosApi'
import moment from 'moment/moment'
import TooltipVideo from '../Tooltip/TooltipVideo'
import Modal from '../Modal/Modal'
import Loader from '../Loader'
import './ReadyCreos.css'


const ReadyCreos = () => {
    const dispatch = useDispatch()
    const creosStore = useSelector(state => state.creos.creos)
    const userStore = useSelector(state => state.user.user)
    const myCreos = creosStore.filter(creo => creo.readyGeos.length !== 0).sort((a, b) => a.creoName > b.creoName ? 1 : -1)
    const [modalActive, setModalActive] = useState(false)
    const [tooltipActive, setTooltipActive] = useState(false)
    const [findCreo, setFindCreo] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (creosStore.length === 0) {
            getFreeCreos(userStore.webName).then(data => {
                dispatch(addArrayCreos(data))
            })
        }
        setTimeout(() => setLoading(false), 800)
    }, [])

    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        }
    }

    const modalHandler = (e) => {
        setModalActive(true)
        setFindCreo(myCreos.find(creo => creo.creoName === e.target.id))
    }

    return (
        <div className={'form'}>
            {/* {userStore.role === 'CreoUnit' || userStore.role === 'CreoUnitHelper' || userStore.role === 'TechHelper' ?
                <h3>Нет доступа</h3>
            : */}
                <>
                    {loading ?
                        <Loader />
                    :
                        <>
                            <table className={'table'}>
                                <tbody>
                                    <tr>
                                        <td>Всего готовых креативов</td>
                                        <td>{myCreos.length}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className={'table'}>
                                <thead>
                                    <tr>
                                        <th>Creo</th>
                                        <th>GEO</th>
                                        <th>Preview</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {myCreos.map(item => {
                                        return (
                                            <tr>
                                                <td>{item.creoName}</td>
                                                <td>{item.readyGeos.join(' ')}</td>
                                                <td><button id={item.creoName} onClick={modalHandler} className={'webBtnMyCreo'}>Preview</button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {modalActive && (
                                <section className={'section'}>
                                    <Modal 
                                        active={modalActive}
                                        setActive={setModalActive}>
                                            <div className={'popupContent'}>
                                                <TooltipVideo item={findCreo} tooltipActive={tooltipActive} setTooltipActive={setTooltipActive} />
                                                <div className={'discriptionCreoPopup'}>
                                                    <div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'appName'}>{findCreo.creoName}</div>
                                                        </div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'discription_main'}>Template: {findCreo.template}</div>
                                                        </div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'title'}>Duration: {findCreo.duration}</div>
                                                        </div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'link'}>Topic: <br/> {findCreo.hashes.join(' ')}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'discription_main'}>Refs: {findCreo.refers.join(' ')}</div>
                                                        </div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'discription_main'}>Release: {moment(findCreo.release_date).format("hh:mm:ss DD.MM.YYYY")}</div>
                                                        </div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'discription_main'}>Formats: {findCreo.formats}</div>
                                                        </div>
                                                        {/* <div className={'wrapperCreo'}>
                                                            <div className={'discription_main'}>My GEO: <br/> {findCreo.myCreoGeos.join(' ')}</div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                    </Modal>
                                </section>
                            )}
                        </>
                    }
                </>
            {/* } */}
        </div>
    )
}

export default ReadyCreos
