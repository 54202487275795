import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import { addArrayCreos, resetCreos } from '../../store/creosReducer'
import { addArrayFilteredCreos, resetFilteredCreos } from '../../store/filteredCreosReducer'
import { getFreeCreos } from '../../http/creosApi'
import { getGeos } from '../../http/geosApi'
import moment from 'moment/moment'
import TooltipVideo from '../Tooltip/TooltipVideo'
import Modal from '../Modal/Modal'
import Loader from '../Loader'
import './MyCreos.css'


const MyCreos = () => {
    const dispatch = useDispatch()
    const creosStore = useSelector(state => state.creos.creos)
    const userStore = useSelector(state => state.user.user)
    const filteredCreosStore = useSelector(state => state.filteredCreos.filteredCreos)
    const myCreos = creosStore.filter(creo => creo.myCreoGeos.length !== 0).sort((a, b) => a.creoName > b.creoName ? 1 : -1)
    const [modalActive, setModalActive] = useState(false)
    const [tooltipActive, setTooltipActive] = useState(false)
    const [findCreo, setFindCreo] = useState({})
    const [loading, setLoading] = useState(true)
    const [multiOptionTemplate, setMultiOptionTemplate] = useState([])
    const [multiOptionCreoName, setMultiOptionCreoName] = useState([])
    const [multiOptionTopic, setMultiOptionTopic] = useState([])
    const [multiOptionGeo, setMultiOptionGeo] = useState([])
    const [templates, setTemplates] = useState([])
    const [creoNames, setCreoNames] = useState([])
    const [geo, setGeo] = useState([])
    const [fullGeo, setFullGeo] = useState([])
    const [hashes, setHashes] = useState([])

    useEffect(() => {
        if (creosStore.length === 0) {
            getFreeCreos(userStore.webName).then(data => {
                dispatch(addArrayCreos(data))
            })
        }
        setTimeout(() => setLoading(false), 800)
    }, [])

    useEffect(() => {
        currentFilter()
        setTimeout(() => setLoading(false), 800)
    }, [multiOptionTemplate, multiOptionTopic, multiOptionGeo])

    useEffect(() => {
        const getOptionsFromDB = async () => {
            const getGeosFromDB = async () => {
                const data = await getGeos().then(geos => {
                    const templates = Array.from(new Set(creosStore.map(el => el.template)))
                    const modifyTemp = templates.map(element => {
                        return {
                            value: element,
                            label: element,
                            color: '#000'
                        }
                    })
                    setTemplates(modifyTemp)

                    const creoNames = creosStore.map(el => el.creoName)
                    const modifyCreoNames = creoNames.map(element => {
                        return {
                            value: element,
                            label: element,
                            color: '#000'
                        }
                    })
                    setCreoNames(modifyCreoNames.sort((a, b) => a.value > b.value ? 1 : -1))

                    const topics = Array.from(new Set(creosStore.map(el => el.hashes[0])))
                    const modifyTopics = topics.map(element => {
                        return {
                            value: element,
                            label: element,
                            color: '#000'
                        }
                    })
                    setHashes(modifyTopics)

                    const modifyGeo = geos[0].geos.map(element => {
                        return {
                            value: element,
                            label: element,
                            color: '#000'
                        }
                    })
                    setGeo(modifyGeo)
                    setFullGeo(modifyGeo)
                })
            }
            getGeosFromDB()
        }
        // if (user) {
            getOptionsFromDB()
            // setFilteredCreos(creosStore)
        // }
    }, [creosStore])


    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    const currentFilter = () => {
        const newArr = myCreos.filter(item => {
            const newObj = 
                {
                    template: item.template,
                    creoName: item.creoName,
                    hashes: item.hashes,
                    geo: item.myCreoGeos,
                    order: item.order
                }

            const multiOption = multiOptionTemplate.concat(multiOptionCreoName).concat(multiOptionTopic)
            
            if (multiOption.some(el => Object.values(newObj).flat().includes(el))) {
                if (multiOptionGeo.every(el => Object.values(newObj).flat().includes(el))) {
                    return item
                } 
            } else {
                if (multiOption.length === 0) {
                    if (multiOptionGeo.every(el => Object.values(newObj).flat().includes(el))) {
                        return item
                    }
                } else {
                    if (multiOptionGeo.every(el => Object.values(newObj).flat().includes(el))) {
                        if (multiOption.some(el => Object.values(newObj).flat().includes(el))) {
                            return item
                        }
                    } 
                }
            }
        })

        const sortedArr = newArr.sort((a, b) => (a.order > b.order) ? 1 : (a.order === b.order) ? ((a.creoName > b.creoName) ? 1 : -1) : -1)
        dispatch(addArrayFilteredCreos(sortedArr))
        
        if (multiOptionGeo.length !== 0) {
            setGeo([])
        } else {
            setGeo(fullGeo)
        }

    }

    const modalHandler = (e) => {
        setModalActive(true)
        setFindCreo(myCreos.find(creo => creo.creoName === e.target.id))
    }

    return (
        <div className={'form'}>
            {/* {userStore.role === 'CreoUnit' || userStore.role === 'CreoUnitHelper' || userStore.role === 'TechHelper' ?
                <h3>Нет доступа</h3>
            : */}
                <>
                    {loading ?
                        <Loader />
                    :
                        <>
                            <div className={'filters'}>
                                <span className={'text'}>
                                    <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} placeholder={'Template'} options={templates} onChange={selectedOption => setMultiOptionTemplate(selectedOption.map(el => el.value))/*setMultiOption(selectedOption.length !== 0 ? [...multiOption, selectedOption[0].value] : multiOption.filter(item => !(/^(?:[0-9]+|)(?:[0-9]+|-[0-9]+)/gm).test(item)))*/} isMulti />
                                </span>
                                <span className={'text'}>
                                    <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} placeholder={'Topic'} options={hashes} onChange={selectedOption => setMultiOptionTopic(selectedOption.map(el => el.value))/*setMultiOption(selectedOption.length !== 0 ? [...multiOption, selectedOption[0].value] : multiOption.filter(item => !(/(?:[A-Z]|[А-Я])(?:[a-z]+|[а-я]+)/gm).test(item)))*/} isMulti />
                                </span>
                                <span className={'text'}>
                                    <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} placeholder={'Geo'} options={geo} onChange={selectedOption => setMultiOptionGeo(selectedOption.length !== 0 ? [...multiOptionGeo, selectedOption[0].value] : multiOptionGeo.filter(item => !(/[A-Z][A-Z]$/gm).test(item)))}/*setMultiOption(selectedOption.length !== 0 ? [...multiOption, selectedOption[0].value] : multiOption.filter(item => !(/[A-Z][A-Z]/gm).test(item)))}*/ isMulti />
                                </span>
                            </div>
                            <table className={'table'}>
                                <tbody>
                                    <tr>
                                        <td>Всего креативов в работе</td>
                                        <td>{myCreos.length}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className={'table'}>
                                <thead>
                                    <tr>
                                        <th>Creo</th>
                                        <th>GEO</th>
                                        <th>Preview</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {multiOptionTemplate.length !== 0 || multiOptionCreoName.length !== 0 || multiOptionTopic.length !== 0 || multiOptionGeo.length !== 0 ?
                                        filteredCreosStore.map(item => {
                                            return (
                                                <tr>
                                                    <td>{item.creoName}</td>
                                                    <td>{item.myCreoGeos.join(' ')}</td>
                                                    <td><button id={item.creoName} onClick={modalHandler} className={'webBtnMyCreo'}>Preview</button></td>
                                                </tr>
                                            )
                                        })
                                    :
                                        myCreos.map(item => {
                                            return (
                                                <tr>
                                                    <td>{item.creoName}</td>
                                                    <td>{item.myCreoGeos.join(' ')}</td>
                                                    <td><button id={item.creoName} onClick={modalHandler} className={'webBtnMyCreo'}>Preview</button></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {modalActive && (
                                <section className={'section'}>
                                    <Modal 
                                        active={modalActive}
                                        setActive={setModalActive}>
                                            <div className={'popupContent'}>
                                                <TooltipVideo item={findCreo} tooltipActive={tooltipActive} setTooltipActive={setTooltipActive} />
                                                <div className={'discriptionCreoPopup'}>
                                                    <div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'appName'}>{findCreo.creoName}</div>
                                                        </div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'discription_main'}>Template: {findCreo.template}</div>
                                                        </div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'title'}>Duration: {findCreo.duration}</div>
                                                        </div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'link'}>Topic: <br/> {findCreo.hashes.join(' ')}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'discription_main'}>Refs: {findCreo.refers.join(' ')}</div>
                                                        </div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'discription_main'}>Release: {moment(findCreo.release_date).format("hh:mm:ss DD.MM.YYYY")}</div>
                                                        </div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'discription_main'}>Formats: {findCreo.formats}</div>
                                                        </div>
                                                        <div className={'wrapperCreo'}>
                                                            <div className={'discription_main'}>My GEO: <br/> {findCreo.myCreoGeos.join(' ')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </Modal>
                                </section>
                            )}
                        </>
                    }
                </>
            {/* } */}
        </div>
    )
}

export default MyCreos
