import { React, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import moment from 'moment/moment'
import './AppItem.css'
import TooltipImage from '../Tooltip/TooltipImage'
import android from '../../img/android.png'
import ios from '../../img/apple.png'
import { updateAppByWeb } from '../../http/appsApi'
import { updateApp } from '../../store/appsReducer'

const AppItem = ({item, className, sources}) => {
    const dispatch = useDispatch()
    const date = moment(item.release_date).format("DD.MM.YYYY")
    const userStore = useSelector(state => state.user.user)
    const [tooltipActive, setTooltipActive] = useState(false)
    const [selectedSource, setSelectedSource] = useState('')
    const [isExpanded, setIsExpanded] = useState(false)
    const [showButton, setShowButton] = useState(false)

    const displayedText = isExpanded ? item.geos : item.geos?.slice(0, 20) + (item.geos?.length > 20 ? '...' : '')
    
    const onSendData = useCallback(() => {
        if (userStore.role !== 'TechHelper') {
            updateAppByWeb(item._id, item.appName, userStore.webName, selectedSource).then(data => {
                data.action = 'toWork'
                if (data.message === 'Успешно') {
                    dispatch(updateApp(data.readyApp))
                    alert('Успешно')
                } else {
                    alert('Ошибка! Вы либо уже льете на источник, либо свободных слотов больше нет')
                }
            })
        } else {
            alert('Нет доступа')
        }
    }, [selectedSource])


    useEffect(() => {
        if (selectedSource !== '' || userStore.webName !== 'WebAnton') {
            setShowButton(true)
        }
    }, [selectedSource])

    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        }
    }

  return (
    <div className={'element ' + className}>
        <div className={'wrap'}>
            <TooltipImage item={item}/>
            <div className={'discriptionApps'}>
                <div className={'wrapper'}>
                    <div className={'appName'}>{item.appName} 
                        <img 
                            alt = ''
                            className={'icon'} 
                            src={item.platform === 'android' ? android : ios}
                        />
                    </div>
                    <div className={'discription_main'}>Partner ID: {item.partner}</div>
                    <div className={'title_main'}>{item.age}</div>
                </div>
                <div className={'wrapper'}>
                    <div className={'title'}>Release: {date}</div>
                    <div className={'title'}>Status: {item.status}</div>
                    <div className={'link'}><a className={'link_tg'} href={item.link} target="_blank" rel="noopener noreferrer">Link</a></div>
                </div>
                <div className={'wrapper'}>
                    <div className={'title'}>Source:
                        <span className={'text'}>
                            <Select styles={colorStyles} className={'smallOptions'} options={/*item.partner === '7' ? sources.filter(source => source.value === 'ironsource') : */item.partner === '5' ? sources.filter(source => source.value === 'unity') : sources} onChange={selectedOption => setSelectedSource(selectedOption.value)} />
                        </span>
                    </div>
                </div>
                <div className={'wrapper'}>
                    {item.sources ?
                        // item.partner === '7' ?
                        //     Object.entries(item.sources).map(elem => {
                        //         if (elem[0] === 'ironsource' && elem[1].length === 0) {
                        //             return (
                        //                 <div className={'title'}>🟢{elem[0]}</div>
                        //             )   
                        //         } else if (elem[0] === 'ironsource' && elem[1].length !== 0) {
                        //             return (
                        //                 <div className={'title'}>🔴{elem[0]}</div>
                        //             )  
                        //         }
                        //     })
                        // :
                            Object.entries(item.sources).map(elem => {
                                if (elem[1].length === 0) {
                                    return (
                                        <div className={'title'}>🟢{elem[0]}</div>
                                    )   
                                } else {
                                    return (
                                        <div className={'title'}>🔴{elem[0]}</div>
                                    )   
                                }
                            })
                    :
                        <>
                            {sources.map(elem => {
                                return (
                                    <div className={'title'}>🟢{elem.label}</div>
                                )
                            })}
                        </>
                    }
                </div>
            </div>
            <div className={'wrapperGeos'}>
                {item.geos && (
                    <div className={'title'}>Geos: {displayedText}
                        {item.geos.length > 20 && (
                            <button className={'btnHide'} onClick={() => setIsExpanded(!isExpanded)}>
                                {isExpanded ? 'Hide' : 'Show'}
                            </button>
                        )}
                    </div>
                )}
            </div>
            {showButton && (
                <button className={'webBtn'} onClick={onSendData}>Взять в работу</button>
            )}
        </div>
    </div>
  );
}

export default AppItem
