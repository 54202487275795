import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment/moment'
import Select from 'react-select'
import { addArrayCreoProfits, resetCreoProfits } from '../../store/creoProfitsReducer'
import { addArrayFilteredCreoProfits, resetFilteredCreoProfits } from '../../store/filteredCreoProfitsReducer'
import { getCreoProfits } from '../../http/creoProfitsApi'
import './CreoProfits.css'
import Loader from '../Loader'


const CreoProfits = () => {
    const dispatch = useDispatch()
    const userStore = useSelector(state => state.user.user)
    const creoProfitsStore = useSelector(state => state.creoProfits.creoProfits)
    const filteredCreoProfitsStore = useSelector(state => state.filteredCreoProfits.filteredCreoProfits)
    const [loading, setLoading] = useState(true)
    const [creativeNames, setCreativeNames] = useState([])
    const [geos, setGeos] = useState([])
    const [os, setOs] = useState(['ios', 'android'])
    const [multiOptionCreativeName, setMultiOptionCreativeName] = useState([])
    const [multiOptionGeo, setMultiOptionGeo] = useState([])
    const [multiOptionOs, setMultiOptionOs] = useState([])
    const [groupedOptions, setGroupedOptions] = useState([])
    const [keysToMatch, setKeysToMatch] = useState(['creativeName'])
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' })

    function replaceKeysWithProduct(data) {
        data.forEach(obj => {
            const spend = parseFloat(obj.spend) || 0

            if (spend !== 0) {
                obj.roi = (obj.profit / spend) * 100
            } else {
                obj.roi = 0
            }

        })

        return data
    }

    useEffect( () => {
        dispatch(resetCreoProfits())
        getCreoProfits().then(data => {
            if (data.length !== 0) {
                dispatch(addArrayCreoProfits(data))

                const creativeNamesOnly = Array.from(new Set(data.map(el => el.creativeName.slice(0,6))))

                const modifyCreativeNames = creativeNamesOnly.map(element => {
                    return {
                        value: element,
                        label: element,
                        color: '#000'
                    }
                })
                setCreativeNames(modifyCreativeNames.sort((a, b) => a.value > b.value ? 1 : -1))
                
                const geosOnly = Array.from(new Set(data.map(el => el.country)))

                const modifyGeos = geosOnly.map(element => {
                    return {
                        value: element,
                        label: element,
                        color: '#000'
                    }
                })
                setGeos(modifyGeos.sort((a, b) => a.value > b.value ? 1 : -1))

                const modifyOs = os.map(element => {
                    return {
                        value: element,
                        label: element,
                        color: '#000'
                    }
                })
                setOs(modifyOs)

                const groupedOptions = [
                    {
                      label: "Creos",
                      options: modifyCreativeNames
                    },
                    {
                        label: "Geo",
                        options: modifyGeos
                    },
                    {
                      label: "Os",
                      options: modifyOs
                    }
                ]
                setGroupedOptions(groupedOptions)
            } 
        })
    }, [])


    useEffect(() => {
        
        const addKeyIfNotExists = (key) => {
            setKeysToMatch(prevKeys => {
                if (!prevKeys.includes(key)) {
                    return [...prevKeys, key]
                }
                return prevKeys
            })
        }

        const removeKeyIfExists = (key) => {
            setKeysToMatch(prevKeys => {
                return prevKeys.filter(k => k !== key)
            })
        }

        let updatedKeysToMatch = ['creativeName']
        
        if (multiOptionCreativeName.length === 0) {
            setKeysToMatch(updatedKeysToMatch)
            // setGroupedOptions([{label: "Creos", options: creativeNames}, {label: "Geo", options: geos}, {label: "Os", options: os}])
        }
            // При изменении multiOptionCreativeName добавляем 'country' в keysToMatch
        if (multiOptionCreativeName.length > 0 /*&& (/^[A-Z][A-Z]$/gm).test(multiOptionCreativeName[0])*/) {
            addKeyIfNotExists('country')
            // if (!(/^[A-Z][A-Z]$/gm).test(multiOptionCreativeName[0]) && !(/^(android|ios)$/gm).test(multiOptionCreativeName[0])) {
            //     setGroupedOptions([{label: "Geo", options: geos}, {label: "Os", options: os}])
            // }
        } 
        // else if (multiOptionCreativeName.length > 0 && (/^(android|ios)$/gm).test(multiOptionOs[0])) {
        //     addKeyIfNotExists('os')
        // } else if (multiOptionCreativeName.length > 0) {
        //     addKeyIfNotExists('creativeName')
        // }

        // При изменении multiOptionGeo добавляем 'os' в keysToMatch
        if (multiOptionGeo.length > 0/* && (/^[A-Z][A-Z]$/gm).test(multiOptionCreativeName[0])*/) {
            addKeyIfNotExists('os')
            // if ((/^(android|ios)$/gm).test(multiOptionCreativeName[0])) {
            //     setGroupedOptions([{label: "Creos", options: creativeNames}, {label: "Geo", options: geos}])
            // }
        } 
        // else if (multiOptionGeo.length > 0 && !(/^[A-Z][A-Z]$/gm).test(multiOptionGeo[0]) && !(/^(android|ios)$/gm).test(multiOptionGeo[0])) {
        //     addKeyIfNotExists('creativeName')
        // } else if (multiOptionGeo.length > 0 && (/^(android|ios)$/gm).test(multiOptionGeo[0])) {
        //     addKeyIfNotExists('os')
        // }

        // if (multiOptionOs.length > 0 && (/^(android|ios)$/gm).test(multiOptionOs[0])) {
        //     addKeyIfNotExists('os')
        // } else if (multiOptionOs.length > 0 && !(/^[A-Z][A-Z]$/gm).test(multiOptionOs[0]) && !(/^(android|ios)$/gm).test(multiOptionOs[0])) {
        //     addKeyIfNotExists('creativeName')
        // } else if (multiOptionOs.length > 0 && (/^[A-Z][A-Z]$/gm).test(multiOptionOs[0])) {
        //     addKeyIfNotExists('country')
        // }

    }, [creoProfitsStore, multiOptionCreativeName, multiOptionGeo, multiOptionOs])

    useEffect(() => {
        currentFilter()
        setTimeout(() => setLoading(false), 800)
    }, [keysToMatch])

    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    const currentFilter = () => {
        const multiOptions = multiOptionCreativeName.concat(multiOptionGeo).concat(multiOptionOs)//.concat(multiOptionAdUnits)
    
        function filterArray(objects, filterStrings, keys) {
            return objects.filter(obj =>
                filterStrings.every(str => 
                    keys.some(key => 
                        obj[key].includes(str)
                    )
                )
            )
        }

        // const keys = multiOptionCreativeName.length === 0 ? ['creativeName'] : multiOptionGeo.length === 0 ? ['creativeName', 'country'] : ['creativeName', 'country', 'os']
        const newArr = filterArray(creoProfitsStore, multiOptions, keysToMatch)

        function mergeObjectsByKeys(arr, keysToMatch, keysToSum, partialKey) {
            const groups = {}

            arr.forEach(obj => {
                const groupKey = keysToMatch.map(key => {
                    if (key === partialKey) {
                        const partialValue = obj[partialKey].slice(0, 6)
                        
                        const existingGroup = Object.keys(groups).find(groupKey => {
                            const groupValues = groupKey.split('|')
                            const partialIndex = keysToMatch.indexOf(partialKey)
                            return groupValues[partialIndex].startsWith(partialValue)
                        })
                        
                        // obj[partialKey] = partialValue;

                        return existingGroup ? existingGroup.split('|')[keysToMatch.indexOf(partialKey)] : partialValue
                    }
                    return obj[key]
                }).join('|')
                
                if (!groups[groupKey]) {
                    groups[groupKey] = { ...obj }
                } else {
                    keysToSum.forEach(key => {
                        groups[groupKey][key] += obj[key]
                    })
                }
            })
            
            return Object.values(groups)
        }
        console.log(keysToMatch)
        const ready = mergeObjectsByKeys(newArr, keysToMatch, ['impressions', 'etcpi', 'installs', 'spend', 'regs', 'deps', 'income', 'profit', 'roi'], 'creativeName')
        const dataWithIncome = replaceKeysWithProduct(ready)
        const withoutNull = dataWithIncome.filter(el => el.averagePayout && el.roi !== null)
        const sortedArr = withoutNull.sort((a, b) => a.creativeName > b.creativeName ? 1 : -1)
        console.log(dataWithIncome.filter(el => el.averagePayout && el.roi === null))
        dispatch(addArrayFilteredCreoProfits(sortedArr))
    }

    const handleSort = (key) => {
        let direction = 'ascending'
        
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending'
    }

    if (filteredCreoProfitsStore.length === 0) {
        const sortedData = [...creoProfitsStore].sort((a, b) => {
            if (a[key] < b[key]) {
              return direction === 'ascending' ? -1 : 1
            }
            if (a[key] > b[key]) {
              return direction === 'ascending' ? 1 : -1
            }
            return 0
          })
      
          dispatch(resetCreoProfits())
          dispatch(addArrayCreoProfits(sortedData))
    } else {
        const sortedData = [...filteredCreoProfitsStore].sort((a, b) => {
            if (a[key] < b[key]) {
              return direction === 'ascending' ? -1 : 1
            }
            if (a[key] > b[key]) {
              return direction === 'ascending' ? 1 : -1
            }
            return 0
          })
      
          dispatch(resetFilteredCreoProfits())
          dispatch(addArrayFilteredCreoProfits(sortedData))
    }
    
    setSortConfig({ key, direction })
  }


    return (
        <div className={'form'}>
            {/* {userStore.role === 'TechHelper' || userStore.role === 'User' || userStore.role === 'CreoUnitHelper' ?
                <h3>Нет доступа</h3>
            :
                <> */}
                    {loading ?
                        <Loader />
                    :
                        <>
                            <table className={'сreoProfits'}>
                                <tbody>
                                    <tr>
                                        <td>Всего записей</td>
                                        <td>{creoProfitsStore.length}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={'filtersCreoProfit'}>
                                <span className={'text'}>
                                    <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} options={groupedOptions} onChange={selectedOption => setMultiOptionCreativeName(selectedOption.map(el => el.value))/*(/^[A-Z][A-Z]$/gm).test(selectedOption[0]?.value) ? setMultiOptionGeo(selectedOption.map(el => el.value)) : (/^(android|ios)$/gm).test(selectedOption[0]?.value) ? setMultiOptionOs(selectedOption.map(el => el.value)) : setMultiOptionCreativeName(selectedOption.map(el => el.value))*/} isMulti />
                                </span>
                                {multiOptionCreativeName.length !== 0 && (
                                    <span className={'text'}>
                                        <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} options={groupedOptions} onChange={selectedOption => setMultiOptionGeo(selectedOption.map(el => el.value))/*(/^[A-Z][A-Z]$/gm).test(selectedOption[0]?.value) ? setMultiOptionGeo(selectedOption.map(el => el.value)) : (/^(android|ios)$/gm).test(selectedOption[0]?.value) ? setMultiOptionOs(selectedOption.map(el => el.value)) : setMultiOptionCreativeName(selectedOption.map(el => el.value))*/} isMulti />
                                    </span>
                                )}
                            </div>
                            <table className={'tableCreoProfits'}>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('creativeName')}>Creative Name</th>
                                        {multiOptionCreativeName.length !== 0 && (
                                            <th onClick={() => handleSort('country')}>Country</th>
                                                
                                        )}
                                        {multiOptionCreativeName.length !== 0 && (
                                            <th onClick={() => handleSort('os')}>OS</th>
                                                
                                        )}
                                        <th onClick={() => handleSort('installs')}>Installs</th>
                                        <th onClick={() => handleSort('spend')}>Spend</th>
                                        <th onClick={() => handleSort('regs')}>Regs</th>
                                        <th onClick={() => handleSort('deps')}>Deps</th>
                                        <th onClick={() => handleSort('averagePayout')}>Av.Payout</th>
                                        <th onClick={() => handleSort('income')}>Income</th>
                                        <th onClick={() => handleSort('profit')}>Profit</th>
                                        <th onClick={() => handleSort('roi')}>ROI</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {multiOptionCreativeName.length !== 0 || multiOptionGeo.length !== 0 || multiOptionFormats.length !== 0 || multiOptionAdUnits.length !== 0 ? */}
                                        {filteredCreoProfitsStore.map(item => {
                                            return (
                                                <tr>
                                                    <td>{item.creativeName.slice(0,6)}</td>
                                                    {multiOptionCreativeName.length !== 0 && (
                                                        <td>{item.country}</td>
                                                    )}
                                                    {multiOptionCreativeName.length !== 0 && (
                                                        <td>{item.os}</td>
                                                    )}
                                                    <td>{item.installs}</td>
                                                    <td>{item.spend.toFixed(2)}$</td>
                                                    <td>{item.regs}</td>
                                                    <td>{item.deps}</td>
                                                    <td>{item.averagePayout}$</td>
                                                    <td>{item.income.toFixed(2)}$</td>
                                                    <td>{item.profit.toFixed(2)}$</td>
                                                    <td>{item.roi.toFixed(2)}%</td>
                                                </tr>
                                            )   
                                        })}
                                    {/* :
                                        creoProfitsStore.map(item => {
                                            return (
                                                <tr>
                                                    <td>{item.creativeName}</td>
                                                    <td>{item.country}</td>
                                                    <td>{item.os}</td>
                                                    <td>{item.installs}</td>
                                                    <td>{item.spend.toFixed(2)}$</td>
                                                    <td>{item.regs}</td>
                                                    <td>{item.deps}</td>
                                                    <td>{item.averagePayout}$</td>
                                                    <td>{item.income !== 'NaN' ? item.income.toFixed(2) : ''}$</td>
                                                    <td>{item.profit !== 'NaN' ? item.profit.toFixed(2) : ''}$</td>
                                                    <td>{item.roi === 'NaN' ? '' : item.roi === Infinity ? '' : item.roi.toFixed(2)}%</td>
                                                </tr>
                                            )
                                        })
                                    } */}
                                </tbody>
                            </table>
                        </>
                    }
                {/* </>
            } */}
        </div>
    )
}

export default CreoProfits

        // Функция для добавления ключа, если он отсутствует
        // const addKeyIfNotExists = (key) => {
        //     setKeysToMatch(prevKeys => {
        //         if (!prevKeys.includes(key)) {
        //             return [...prevKeys, key]
        //         }
        //         return prevKeys
        //     })
        // }

        // // Функция для удаления ключа, если он присутствует
        // const removeKeyIfExists = (key) => {
        //     setKeysToMatch(prevKeys => {
        //         return prevKeys.filter(k => k !== key)
        //     })
        // }

        // // Функция для добавления объекта в groupedOptions
        // const addGroupedOptionIfNotExists = (option) => {
        //     setGroupedOptions(prevOptions => {
        //         if (!prevOptions.some(o => o.label === option.label)) {
        //             return [...prevOptions, option]
        //         }
        //         return prevOptions
        //     })
        // }

        // // Функция для удаления объекта из groupedOptions
        // const removeGroupedOption = (label) => {
        //     setGroupedOptions(prevOptions => {
        //         return prevOptions.filter(o => o.label !== label);
        //     })
        // }

        // addKeyIfNotExists('creativeName')

        // if (multiOptionCreativeName.length === 0) {
        //     // Если массив опустел, удаляем 'country', добавляем 'Creos'
        //     removeKeyIfExists('country')
        //     addGroupedOptionIfNotExists({ label: 'Creos', options: creativeNames})
        // } else if (multiOptionCreativeName.length > 0) {
        //     // Если массив не пуст, добавляем 'country', убираем 'Creos'
        //     addKeyIfNotExists('country')
        //     removeGroupedOption('Creos')
        // }
    
        // // Логика для multiOptionGeo
        // if (multiOptionGeo.length === 0) {
        //     // Если массив опустел, удаляем 'country', добавляем 'Geo'
        //     removeKeyIfExists('country')
        //     addGroupedOptionIfNotExists({label: 'Geo', options: geos})
        // } else if (multiOptionGeo.length > 0) {
        //     // Если массив не пуст, добавляем 'country', убираем 'Geo'
        //     addKeyIfNotExists('os')
        //     removeGroupedOption('Geo')
        // }
    
        // // Логика для multiOptionOs
        // if (multiOptionOs.length === 0) {
        //     // Если массив опустел, удаляем 'os', добавляем 'Os'
        //     removeKeyIfExists('os')
        //     addGroupedOptionIfNotExists({label: 'Os', options: os})
        // } else if (multiOptionOs.length > 0) {
        //     // Если массив не пуст, добавляем 'os', убираем 'Os'
        //     // addKeyIfNotExists('os')
        //     removeGroupedOption('Os')
        // }

        // {multiOptionGeo.length !== 0 && (
        //     <span className={'text'}>
        //         <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} options={groupedOptions} onChange={selectedOption => setMultiOptionOs(selectedOption.map(el => el.value))/*(/^[A-Z][A-Z]$/gm).test(selectedOption[0]?.value) ? setMultiOptionGeo(selectedOption.map(el => el.value)) : (/^(android|ios)$/gm).test(selectedOption[0]?.value) ? setMultiOptionOs(selectedOption.map(el => el.value)) : setMultiOptionCreativeName(selectedOption.map(el => el.value))*/} isMulti />
        //     </span>
        // )}